<template>
  <div class="details_box">
    <div class="break">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/newsCenter'}"
          >新闻动态</el-breadcrumb-item
        >
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="details_content">
      <h3>{{ info.title }}</h3>
      <div class="banner">
        <p>发表时间：{{ info.createTime }}</p>
        <p>信息来源：{{ info.source || "未知" }}</p>
      </div>
      <div class="inner_html">
        <div v-html="info.content"></div>
      </div>
      <div class="file_box" v-if="info.fileUrls && info.fileUrls.length != 0">
        <span>附件：</span>
        <div class="item">
          <p v-for="(item, index) in info.fileUrls" :key="index">
            <a :href="item" download="11111">{{
              info.fileNames ? info.fileNames[index] : ""
            }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {articleInfo} from "@/util/api";
export default {
  data() {
    return {
      info: {},
    };
  },
  mounted() {
    articleInfo({
      articleId: this.$route.query.articleId,
    })
      .then((res) => {
        this.info = res.data;
        if (this.info.fileUrls) {
          this.info.fileUrls = this.info.fileUrls.split(";");
          this.info.fileNames = this.info.fileNames.split(";");
        }
      })
      .catch(() => {});
  },
  methods: {
    downLoad(url, name) {
      // const blob = new Blob([url]);
      let a = document.createElement("a");
      a.href = url;
      a.download = name;
      a.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.details_box {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding-bottom: 80px;

  .break {
    padding: 20px 0;
    width: 1200px;
    margin: 0 auto;
  }
}
.details_content {
  position: relative;
  width: 1200px;
  margin: 20px auto;
  background-color: #fff;
  min-height: 400px;
  padding-bottom: 80px;
  h3 {
    font-size: 26px;
    color: #333;
    text-align: center;
    padding: 30px 0;
  }
  .banner {
    width: 95%;
    margin: 0 auto;
    height: 40px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    p {
      padding-left: 30px;
      color: #333;
      font-size: 14px;
    }
  }
  .inner_html {
    width: 95%;
    margin: 30px auto 0;
    font-size: 16px;
    color: #333;
  }
  .file_box {
    position: absolute;
    bottom: 30px;
    padding: 0px 30px;
    display: flex;
    span {
      font-size: 14px;
      color: #5586e0;
    }
    .item {
      display: flex;
      flex-direction: column;
      p {
        margin-bottom: 5px;
        font-size: 14px;
        color: #333;
        a {
          text-decoration: none;
          color: #333;
        }
        :hover {
          color: #5586e0;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
